export const ENVIRONMENT = {
  production: false,
  name: 'DEV',
  registru: {
    apiUrl: 'https://staging.registru.stanleybet-cloud.com/api/v1',
    apiKey: 'ebf8273e-ecb5-4003-ad6d-641a76b223ab'
  },
  cashDesk: {
    apiUrl: 'https://staging.cashdesk-api.stanleybet-cloud.com/api/v1',
    apiKey: '5dbaf89c-2cae-4d08-9453-1652374edffd',
  },
};

export const APP_CONFIGURATION = {
  name: 'registru-pariuri',
  uris: {
    authentication: {
      mainUrl: '/authentication',
      login: '/login',
      refreshToken: '/refresh-token',
    },
    admin: {
      mainUrl: '/admin',
      users: '/users',
      betRegister: '/bet-cash-register-and-monetary-reports',
      transactions: '/transfer-types',
    },
    shop: {
      mainUrl: '/shop',
      shopList: '/get-shop-list',
    },
  },
};
